import React from 'react';
import { Button } from './ui/button';
import { Link, useLocation } from 'react-router-dom';
import { Separator } from '@radix-ui/react-select';

function Footer() {

  const location = useLocation();

  // Define an array of image paths.
  const images = [
    '/assets/images/banners/museumcircle_banner0.jpg',
    '/assets/images/banners/museumcircle_banner1.jpg',
    '/assets/images/banners/museumcircle_banner3.jpg',
    '/assets/images/banners/museumcircle_banner4.jpg',
    '/assets/images/banners/museumcircle_banner6.jpg',
    '/assets/images/banners/museumcircle_banner7.jpg',
    '/assets/images/banners/museumcircle_banner8.jpg',
    '/assets/images/banners/museumcircle_banner11.jpg',
    '/assets/images/banners/museumcircle_banner12.jpg',
    '/assets/images/banners/museumcircle_banner13.jpg',
    '/assets/images/banners/museumcircle_banner16.jpg',
    '/assets/images/banners/museumcircle_banner18.jpg',
    '/assets/images/banners/museumcircle_banner19.jpg',
    '/assets/images/banners/museumcircle_banner20.jpg',
    '/assets/images/banners/museumcircle_banner21.jpg',
    '/assets/images/banners/museumcircle_banner22.jpg',
    '/assets/images/banners/museumcircle_banner23.jpg',
    '/assets/images/banners/museumcircle_banner24.jpg',
    '/assets/images/banners/museumcircle_banner25.jpg',
    '/assets/images/banners/museumcircle_banner27.jpg',
    '/assets/images/banners/museumcircle_banner28.jpg',
    '/assets/images/banners/museumcircle_banner29.jpg',
    '/assets/images/banners/museumcircle_banner30.jpg',
    '/assets/images/banners/museumcircle_banner31.jpg',
    '/assets/images/banners/museumcircle_banner32.jpg',
    '/assets/images/banners/museumcircle_banner33.jpg',
    '/assets/images/banners/museumcircle_banner34.jpg',
    '/assets/images/banners/museumcircle_banner35.jpg',
    '/assets/images/banners/museumcircle_banner36.jpg',
    // Add as many images as you want.
  ];

  // Use useMemo to select a random image once when the component mounts.
  const randomImage = React.useMemo(() => {
    return images[Math.floor(Math.random() * images.length)];
  }, [location.pathname]);

  return (
    <footer className="py-0 px-0 font-custom_medium rounded-3xl m-2">
      <div className="relative mb-2 mx-0">
        <img
          src={randomImage}
          alt="Footer Banner"
          className="w-full h-[32rem] object-cover rounded-3xl"
        />
      </div>

      {/* Container for the links */}
      <div className="flex flex-col md:flex-row justify-between items-center gap-0 px-4">
        {/* Social Media (3 links) */}
        <div className="flex flex-wrap justify-center gap-2">
          <a
            href="https://www.instagram.com/cypher._studio"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            <Button
              variant="link"
              className="focus:outline-none focus:ring-0 focus-visible:outline-none bg-transparent text-black text-sm"
            >
              Instagram
            </Button>
          </a>
          <a
            href="https://www.linkedin.com/company/cypher-studio/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            <Button
              variant="link"
              className="focus:outline-none focus:ring-0 focus-visible:outline-none bg-transparent text-black text-sm"
            >
              LinkedIn
            </Button>
          </a>
          <a
            href="https://online.museumcircle.org"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            <Button
              variant="link"
              className="focus:outline-none focus:ring-0 focus-visible:outline-none bg-transparent text-black text-sm"
            >
              Museum Circle
            </Button>
          </a>
        </div>

        {/* Privacy and Contact (2 links) */}
        <div className="flex flex-wrap justify-center gap-2">
          <Button
            asChild
            variant="link"
            className="focus:outline-none focus:ring-0 focus-visible:outline-none bg-transparent text-black text-sm hover:text-black"
          >
            <Link to="/privacystatement">Privacy Statement</Link>
          </Button>
          <Button
            asChild
            variant="link"
            className="focus:outline-none focus:ring-0 focus-visible:outline-none bg-transparent text-black text-sm hover:text-black"
          >
            <Link to="/contact">Contact</Link>
          </Button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

