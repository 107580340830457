import React, { useState } from "react";

function CustomCheckbox({ checked: checkedProp, onChange, ...props }) {
  // Determine if we're controlled or not
  const isControlled = checkedProp !== undefined;
  const [internalChecked, setInternalChecked] = useState(false);
  const checked = isControlled ? checkedProp : internalChecked;

  const handleChange = (e) => {
    if (!isControlled) {
      setInternalChecked(e.target.checked);
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label className="flex items-center cursor-pointer">
      {/* The hidden native checkbox for accessibility */}
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className="hidden"
        {...props}
      />
      {/* Custom checkbox UI */}
      <div
        className={`w-5 h-5 border-0 rounded-sm flex items-center justify-center transition-colors 
          ${checked ? "bg-white border-blue-500" : "bg-[#f5f5f5] border-gray-300"}`}
      >
        {checked && (
          <svg
            className="w-4 h-4 text-[#4d4dff]"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        )}
      </div>
    </label>
  );
}

export default CustomCheckbox;


