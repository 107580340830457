import React from 'react';
import { useEffect } from 'react'


function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      
    <div className='text-start max-w-prose mx-auto my-24 md:my-36 px-8'>
    <div className=' text-white my-12'>
            <p className="text-3xl font-custom  mb-8">
              About us
            </p>
            <p className="text-sm font-sans">
            Museum Circle is a community for museum professionals, researchers, and makers dedicated to reimagining the museum experience through collaboration. We foster knowledge exchange, support the adoption of emerging technologies, and create opportunities for networking and shared expertise. By working together, we explore innovative tools and practices that transform how museums connect with their audiences.
<br></br><br></br>
            Launched in 2025, Museum Circle is maintained independently by curators, programmers and developers working in the museum field.

            </p>
          </div>

          <div className=' text-white my-12'>
            <p className="text-3xl font-custom  mb-8">
              Our mission
            </p>
            <p className="text-sm font-sans">
            Our mission is to build a dynamic network of professionals dedicated to transforming the museum experience. We bring together diverse voices to explore, develop, and share digital tools and methodologies that shape the future of audience engagement. Through open dialogue, collaboration, and access to digital resources, we drive innovation that makes museums more interactive, inclusive, and inspiring.</p>
          </div>
</div>

    </>
  );
}

export default About;
