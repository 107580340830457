import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabaseClient';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import CustomCheckbox from "@/components/ui/checkbox_custom";

import countries from 'world-countries';
import { Link } from 'react-router-dom'


function Request() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const applicationFormRef = useRef(null);

  // This function scrolls the application form into view smoothly
  const scrollToForm = () => {
    if (applicationFormRef.current) {
      applicationFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigate = useNavigate();
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const formSchema = z.object({
    first_name: z.string().min(1, { message: 'Required' }),
    last_name: z.string().min(1, { message: 'Required' }),
    job_title: z.string().min(1, { message: 'Required' }),
    organisation: z.string().min(1, { message: 'Required' }),
    email: z.string().email('Invalid email address'),
    country: z.string().min(1, { message: 'Required' }),
    terms: z.literal(true, {
      errorMap: () => ({ message: 'You must accept the terms and conditions to continue' }),
    }),
  });
  
  

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      job_title: '',
      organisation: '',
      email: '',
      country: '',
      terms: false,
    },
  });
  
    const onSubmit = async (data) => {

      // Insert the data into your "Wave 3" table
      const { error } = await supabase
        .from("wave_3")
        .insert([
          {
            first_name: data.first_name,
            last_name: data.last_name,
            job_title: data.job_title,
            organisation: data.organisation,
            email: data.email,
            country: data.country,
            terms: data.terms,
          },
        ]);

        if (error) {
          console.error("Error submitting application:", error);
          setSubmissionStatus({ type: 'error', message: "Something went wrong. Please try again." });
        } else {
          console.log("Application submitted successfully!");
      
          // Send confirmation email via your serverless function
          try {
            const response = await fetch('/api/sendConfirmation', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: data.email, first_name: data.first_name }),
            });
            console.log("Raw response:", response);
            const result = await response.json();
            console.log("JSON result:", result);
            if (result.error) {
              setSubmissionStatus({ type: 'error', message: "Application submitted, but confirmation email not sent." });
            } else {
              setSubmissionStatus({ type: 'success', message: "Your application has been received, and a confirmation email has been sent!" });
            }
          } catch (emailError) {
            console.error("Error sending confirmation email:", emailError);
            setSubmissionStatus({ type: 'error', message: "Application submitted, but error sending confirmation email." });
          }
      
          form.reset();
        }

    };
    

  const countryOptions = countries
  .map((country) => ({
    label: country.name.common,
    value: country.cca2,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));


  return (
    <>
      <div
        className='max-w-prose mx-auto my-12'> {/* Add mx-auto to center the div */}
<div className='mx-auto text-start max-w-prose px-4 my-24 xl:my-36'>

    <div className=' text-white my-16 px-4'>
            <p className="text-3xl font-custom mb-8">
            Apply today and get free lifetime access
            </p>
            <p className="text-sm font-sans">
            Be among the first to shape our community! Early members will receive exclusive free lifetime access (limited spots available). To ensure a meaningful and professional space, all applications are carefully reviewed by our team. Membership is reserved for museum professionals and partners actively working in the field.


            </p>
          </div>

          <div className=' text-white my-2 rounded-xl hover:bg-[#6666ff] p-4'>
            <p className="text-3xl font-custom">
            Wave 1 – Young professionals
            </p>
            <p className="text-sm font-sans my-2">

            (max. 50) | free lifetime access
            </p>
            <p className='p-2 bg-[#ff6666] w-fit rounded-xl font-custom_medium'>unavailable</p>
          </div>

          <div className=' text-white my-2 rounded-xl hover:bg-[#6666ff] p-4'>
            <p className="text-3xl font-custom">
            Wave 2 – Founding members
            </p>
            <p className="text-sm font-sans my-2 ">

            (max. 100) | free lifetime access
            </p>
            <p className='p-2 bg-[#ff6666] w-fit rounded-xl font-custom_medium'>unavailable</p>
          </div>

          <div className=' text-white my-2 rounded-xl hover:bg-[#6666ff] p-4'>
          <Link to="#" onClick={scrollToForm} className="text-white">
            <p className="text-3xl font-custom">Wave 3 – Early adopters</p>
            <p className="text-sm font-sans my-2">
              (max. 250) | free lifetime access<br />
              If approved, access will be granted before May 6th.<br />
            </p>
            <p className="p-2 bg-[#21aeff] w-fit rounded-xl font-custom_medium">waitlist open</p>
          </Link>
          </div>

          <div className=' text-white my-2 rounded-xl hover:bg-[#6666ff] p-4'>
            <p className="text-3xl font-custom">
            Wave 4 – Supporters
            </p>
            <p className="text-sm font-sans my-2 ">

            (max. 500) | €49 a year + pro features<br></br>
            *custom pricing for institutional and partner memberships.
            </p>
            <p className='p-2 bg-[#6666ff] w-fit rounded-xl font-custom_medium'>coming soon</p>
          </div>

          <div className=' text-white my-2 rounded-xl hover:bg-[#6666ff] p-4'>
            <p className="text-3xl font-custom">
            Wave 5 – General admission
            </p>
            <p className="text-sm font-sans my-2 ">

            (no max.) | pricing to be announced<br></br>
            </p>
            <p className='p-2 bg-[#6666ff] w-fit rounded-xl font-custom_medium'>coming soon</p>
          </div>
</div>

<div className='mx-auto text-start max-w-prose my-24 xl:my-36 mb-36 xl:mb-48 px-8'>
<p className="text-3xl text-white font-custom  mb-8">
            Your application
            </p>
            
<section id="applicationForm" ref={applicationFormRef}>
        <Form {...form}>
  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">

{/* First Name and Last Name */}

<div
className='flex space-x-2'>
      {/* First Name */}
      <FormField
        control={form.control}
        name="first_name"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel className="font-sans text-white text-base">First name*</FormLabel>
            <FormControl>
              <Input className="my-2 bg-transparent border-2 border-white font-sans text-white placeholder:text-slate-300"
              placeholder="First name" {...field} />
            </FormControl>
            <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
          </FormItem>
        )}
      />

      {/* Last Name */}
<FormField
        control={form.control}
        name="last_name"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel className="font-sans text-white text-base">Last name*</FormLabel>
            <FormControl>
              <Input className="my-2 bg-transparent border-2 border-white font-sans text-white placeholder:text-slate-300"
              placeholder="Last name" {...field} />
            </FormControl>
            <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
          </FormItem>
        )}
      />
</div>

    {/* email */}
    <FormField
      control={form.control}
      name="job_title"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="font-sans text-white text-base">Job title*</FormLabel>
          <FormControl>
            <Input className="my-2 bg-transparent border-2 border-white font-sans text-white placeholder:text-slate-300"
      variant="outline"
            placeholder="e.g. Curator" {...field} />
          </FormControl>
          <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
        </FormItem>
      )}
    />

        {/* email */}
        <FormField
      control={form.control}
      name="organisation"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="font-sans text-white text-base">Organisation*</FormLabel>
          <FormControl>
            <Input className="my-2 bg-transparent border-2 border-white font-sans text-white placeholder:text-slate-300"
            placeholder="e.g. Museum Circle" {...field} />
          </FormControl>
          <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
        </FormItem>
      )}
    />

        {/* email */}
        <FormField
      control={form.control}
      name="email"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="font-sans text-white text-base">Email*</FormLabel>
          <FormControl>
            <Input className="my-2 bg-transparent border-2 border-white font-sans text-white placeholder:text-slate-300"
            placeholder="Work email" {...field} />
          </FormControl>
          <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
        </FormItem>
      )}
    />

{/* Country Selector */}
<FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-sans text-white text-base">Country*</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger className="my-2 bg-transparent border-2 border-white font-sans text-slate-300 hover:border-[#6666ff]"
                  style={{color : field.value && field.value.length > 0 ? "white" : "slate-300"}}>
                    <SelectValue placeholder="Country"/>
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {countryOptions.map((country) => (
                    <SelectItem className="hover:bg-[#4d4dff] focus:bg-[#4d4dff]" key={country.value} value={country.label}>
                      {country.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
            </FormItem>
          )}
        />


    {/* Terms and Conditions */}

    <FormField
  control={form.control}
  name="terms"
  render={({ field }) => (
    <FormItem className="flex flex-col dark pt-8 pb-4">
<div className='flex items-center space-x-2'>
      <FormControl>
        <CustomCheckbox
          checked={field.value}
          onChange={field.onChange}
        />
      </FormControl>
      <FormLabel className=" text-white px-4 items-center">I consent to my personal data being processed to assess my application and agree to the&nbsp;<Link to="/privacystatement" target="_blank" className='font-sans_bold text-white hover:text-white'>privacy statement</Link>.</FormLabel>
      </div>
      <FormMessage className="text-[white] bg-[#ff6666] w-fit p-1 px-2 rounded-sm" />
    </FormItem>
  )}
/>

    {/* Submit Button */}
    <Button type="submit" variant='secondary' className="w-full dark bg-[#f5f5f5] font-sans text-[#4d4dff] hover:text-[#4d4dff] hover:bg-white">
      Submit →
    </Button>
  </form>
</Form>
</section>

{/* message */}

{submissionStatus && (
      <div
        className={`my-4 rounded-lg px-4 py-2 text-center 
          ${submissionStatus.type === 'success'
            ? 'bg-green-100 text-green-600 font-sans text-sm'
            : 'bg-red-100 text-red-600 border font-sans text-sm'
          }`}
      >
        {submissionStatus.message}
      </div>
)}
</div>



      </div>

    </>
  );
}

export default Request;
