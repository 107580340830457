import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from '@/components/ui/button';
import { Separator } from '@radix-ui/react-select';
import { useEffect } from 'react'

function HowWeWork() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

<div className='text-start max-w-prose mx-auto my-24 md:my-36 px-8'>
    <div className=' text-black my-12'>
            <p className="text-3xl font-custom  mb-8">
              How we work
            </p>
            <p className="text-2xl font-sans">
            Our studio takes on projects in the field of museums, galleries and archives to stimulate more accessible and interactive presentation methods powered by digital technology. <br></br><br></br>

Our work is research-driven and seeks to uplift a contemporary approach to engaging with audiences. Here we build digital experiences for both online and real-world visitors. <br></br><br></br>

Our goal is not to just make cool stuff. We believe that digital technology can be a solution to real-world challenges. We highlight this with our conceptual and experience-focused approach. Because our team has in-house expertise of working at museums, we understand the challenges and policies your organisation may have. <br></br><br></br>


            </p>
          </div>

          <div className=' text-black my-12'>
            <p className="text-3xl font-custom  mb-8">
              Outcome
            </p>
            <p className="text-2xl font-sans">
            Our most important work is to help organisations explore their potential within digital culture. Whether that’s with augmented reality, virtual reality, artificial intelligence or just a very clever website. It’s important to find something that fits your organisation and audience.<br></br><br></br>

What we offer is a well-designed, working prototype for your project (e.g. an exhibition, collection launch or archive), which can be monitored for further development. Because we develop our platforms sustainably, it’s always possible to alter the outcome where necessary.</p>
          </div>

          <div className=' text-black my-12'>
            <p className="text-3xl font-custom  mb-8">
              Timeframe
            </p>
            <p className="text-2xl font-sans">
            We can deliver most projects within 8-12 weeks. This includes time for meetings, user-testing and installation.</p>
          </div>

          <div className=' text-black my-12'>
            <p className="text-3xl font-custom  mb-8">
              Pricing
            </p>
            <p className="text-2xl font-sans">
            We believe in transparent pricing, and calculate your project based on the amount of days our team will be occupied with your project. 
  <br></br><br></br>
➤ Development: €2100 a day<br></br>
➤ Consultation: €262,50 an hour
<br></br><br></br>
Based on your budget or digital ambition we can propose a feasible solution for your organisation.
  </p>
          </div>

          <div className=' text-black my-12'>
            <p className="text-3xl font-custom  mb-8">
              Sign up
            </p>
            <p className="text-sm font-sans">
            To sign up, please schedule an appointment with us, so we can discuss your digital transition.
  <br></br><br></br>
museums / archives / education<br></br>
➤ gianni@cypherstudio.org</p>
          </div>
</div>

    </>
  );
}

export default HowWeWork;