import React from 'react';
import { useEffect } from 'react'

function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <div className='mx-auto text-center max-w-prose my-24 xl:my-36 px-8'>
        <div className=' text-black'>
          {/* <p className="text-3xl font-custom  mb-12">
            Get in touch
          </p> */}
          <div
            className='my-12'>
            <p className="text-lg font-sans">
              All enquiries:</p>
            <p className="text-2xl font-sans  mb-12">
              hello@cypherstudio.org
            </p>
          </div>
          <div
            className='my-12'>
            <p className="text-lg font-sans">
              Office:</p>
            <p className="text-2xl font-sans  mb-12">
              <a
              className=' text-black font-custom_bold hover:text-black'>Cypher Studio</a> <br></br>
              Sint-agathastraat 54 <br></br>
              3037 SH Rotterdam <br></br>
              The Netherlands
            </p>
          </div>
          <div
            className='my-12'>
            <p className="text-lg font-sans text-[#706f6f]">
            © 2025</p>
          </div>
        </div>
      </div>

      {/* <div className='relative mb-2 mx-2'>
        <img
          src={"/src/assets/images/museumcircle_banner00.jpg"}
          alt={`${"hello"} Hover`}
          className="w-full h-[32rem] object-cover rounded-3xl "
        />
      </div> */}

    </>
  );
}

export default Contact;
