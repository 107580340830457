import React, { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectLabel
} from "@/components/ui/select"
import { useEffect } from 'react'

function PrivacyStatement() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedPolicy, setSelectedPolicy] = useState('privacy');

  const policiesData = {
    privacy: {
      displayName: 'Privacy Statement',
      content: (
        <>
      <h1 className="text-xl text-black font-sans mb-4">Privacy Statement</h1>
      <p className="text-sm text-[black] mb-2 font-custom">Last updated – 24 March 2025</p>

      <section className="mb-8 text-sm text-black font-sans">
        <p className="mb-4">
          During the processing of personal data, Cypher Studio works conform the requirements of the applicable data protection legislation, like the General Data Protection Regulation. This means we:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Clearly specify our purposes before we process personal data, by using this privacy statement;</li>
          <li>Limit our collection of personal data to only the personal data needed for legitimate purposes;</li>
          <li>First ask for explicit permission to process your personal data in cases where your permission is required;</li>
          <li>Take appropriate security measures to protect your personal data and demand the same from parties who process personal data on our behalf;</li>
          <li>Respect your right to access, correct or delete your personal data held by us.</li>
        </ul>
        <p>
          Cypher Studio is the party responsible for all data processing. In this privacy statement, we will explain what kind of personal data we collect and for which purposes within our web shop Cypher Studio We recommend that you read it carefully.
        </p>
      </section>

      <section className="mb-8 text-sm text-black font-sans">
        <h2 className="text-xl  mb-4 font-sans">Statistics and profiling</h2>
        <p className="mb-4">
          We keep statistics on the use of our web shop. These statistics help us to, for example, only show you information that is relevant to you. We may combine personal data to get to know more about you. We will of course respect your privacy at all times. If you do not want us to do these statistics, please let us know.
        </p>
        <p className="mb-4">
          For this purpose, we use your invoice address, gender, phone number, IP address, email address, name and address details, and birth date. We do this on the basis of your consent. We store this information for one year.
        </p>
      </section>

      <section className="mb-8 text-sm text-black font-sans">
        <h2 className="text-xl  mb-4 font-sans">Handling your order</h2>
        <p className="mb-4">
          We will use your personal data when handling your order. We may share your data with the delivery service to have your order delivered. We may also obtain information on your payment from your bank or credit card company.
        </p>
        <p className="mb-4">
          For this purpose, we use your payment details, phone number, invoice address, email address, name and address details, and gender. We do this on the basis of your consent. We store this information until your order has been completed. Certain types of personal data will be retained for a longer period with regards to the legal tax retention (the legal retention period).
        </p>
      </section>

      <section className="mb-8 text-sm text-black font-sans">
        <h2 className="text-xl  mb-4 font-sans">Registration</h2>
        <p className="mb-4">
          Certain features of our service require you to register beforehand. You will have to provide some information about yourself and choose a username and password for the account that we will set up for you.
        </p>
        <p className="mb-4">
          For this purpose, we use your birth date, gender, name and address details, answers to our questionnaires, interests, descriptions of events, names of people you may mention, opinions, cultural background, sexual identity, email address, and phone number. We do this on the basis of your consent. We store this information until you close your account.
        </p>
      </section>

      <section className="mb-8 text-sm text-black font-sans">
        <h2 className="text-xl  mb-4 font-sans">Sending newsletters</h2>
        <p className="mb-4">
          We have a newsletter to inform those interested about news, tips, and information about our products and services. Your e-mail address is automatically added to the list of subscribers.
        </p>
        <p className="mb-4">
          For this purpose, we use your email address, gender, phone number, birth date, invoice address, and name and address details. We do this on the basis of your consent. We store this information until you cancel your subscription.
        </p>
      </section>

      {/* Add more sections here following the same structure */}
        </>
      ),
    },
    cookie: {
      displayName: 'Cookie Policy',
      content: (
        <>
          <h1 className="text-xl  mb-4 text-black font-sans">Cookie Policy</h1>
          <p className="text-sm mb-2 text-[black] font-custom">Last updated – 5 March 2025</p>
          {/* ... Add the cookie policy content here */}
          <section className="mb-8 text-sm text-black font-sans">
            <p className="mb-4">
              Currently we're not collecting cookies on this website.
            </p>
            {/* Add more sections as needed */}
          </section>
        </>
      ),
    },

  };
  

  return (
<>


    <div className="max-w-prose mx-auto py-0">

    <div
      className='text-center max-w-prose mx-auto my-12 text-black font-sans'> {/* Add mx-auto to center the div */}
      <h1 className="text-3xl font-bold mb-6 font-sans">Legal Statements</h1>

</div>

    <div className="my-12">
    <Select
  value={selectedPolicy}
  onValueChange={(value) => setSelectedPolicy(value)}
>
  <SelectTrigger className="text-[black] no-border bg-white my-6">
    <SelectValue>
      {policiesData[selectedPolicy]?.displayName || 'Select a policy'}
    </SelectValue>
  </SelectTrigger>
  <SelectContent>
    <SelectGroup>
      {Object.keys(policiesData).map((key) => (
        <SelectItem key={key} value={key}>
          {policiesData[key].displayName}
        </SelectItem>
      ))}
    </SelectGroup>
  </SelectContent>
</Select>

    </div>

    <div className="max-w-prose mx-auto py-0">
  {/* ...Your header and introductory text... */}

  {/* Render the selected policy content */}
  {policiesData[selectedPolicy]?.content}
</div>



      <section className="mb-8 text-sm text-black font-sans">
        <h2 className="text-xl  mb-4 font-sans ">Contact details</h2>
        <p>
          Cypher Studio<br />
          Sint-Agathastraat 54, unit 0.16<br />
          3037SH Rotterdam<br /><br />
          hello@cypherstudio.org<br />
          +31 6 139 020 64
        </p>
      </section>
    </div>
    </>
  );
}

export default PrivacyStatement;
