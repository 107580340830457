import React from 'react';
import { useEffect } from 'react'

function NotFound() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <div className='mx-auto text-center max-w-prose my-24 xl:my-36 px-8'>

        <div className=' text-white my-24 xl:my-36'>
          <img
            src={"/assets/images/404_image.jpg"}
            alt={`${"hello"} Hover`}
            className="w-full h-auto object-cover rounded-3xl my-8 "
          />
          <p className="text-6xl font-custom  mb-4">
            404
          </p>
          <p className="text-3xl font-custom">
            This page does not exist. <br></br>Try again?
          </p>
        </div>

      </div>

    </>
  );
}

export default NotFound;
