import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from '@/components/ui/button';
import { Separator } from '@radix-ui/react-select';
import { useEffect } from 'react'

const collaborations = [
  // { name: "MoMA", city: "New York" },
  { name: "Van Gogh Museum", city: "Amsterdam" },
  { name: "Fashion for Good", city: "Amsterdam" },
  { name: "Amsterdam Museum", city: "Amsterdam" },
  { name: "Museum Boijmans Van Beuningen", city: "Rotterdam" },
  { name: "Design Museum Den Bosch", city: "Den Bosch" },
  { name: "TENT", city: "Rotterdam" },
  { name: "Felix Meritis", city: "Amsterdam" },
  { name: "AMFI", city: "Amsterdam" },
  { name: "Dutch Design Foundation", city: "Eindhoven" },
  { name: "Huis van het Boek", city: "The Hague" },
  { name: "Fonds 21", city: "Utrecht" },
  { name: "Reinwardt Academie", city: "Amsterdam" },
  { name: "Hogeschool van Amsterdam", city: "Amsterdam" },
  { name: "Erasmus University Rotterdam", city: "Rotterdam" },
];

function About() {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>


      {/* heading + buttons */}
      {/* <div className="text-center max-w-prose mx-auto my-24 xl:my-36 px-8">
  <p className="text-3xl font-sans text-black">
    Home of digital museum experiences
  </p>
  <div className="flex flex-col md:flex-row justify-between my-8 space-y-2 md:space-y-0 md:space-x-2">
    <Button
      asChild
      className="w-full dark bg-[#000] font-sans text-[#fff] hover:text-[#4d4dff] hover:bg-white"
      variant="secondary"
    >
      <Link to="https://online.museumcircle.org" target="_self">See projects</Link>
    </Button>

    <Button
      asChild
      className="w-full dark bg-transparent border-2 border-black font-sans text-black hover:bg-white hover:text-[#4d4dff] hover:border-white"
      variant="outline"
    >
      <Link to="/requestaccess">Let's build →</Link>
    </Button>
  </div>
</div> */}

      {/* image */}
      {/* <div className="m-2 p-2 mx-0">
        <img
          src="/assets/images/banners/ARmirror.gif"
          alt="Footer Banner"
          className="w-full h-[64rem] object-cover rounded-3xl"
        />
      </div> */}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 items-center text-left mx-auto my-36 md:my-48 px-[15%]">
        <div className=''>
          <p className="text-2xl font-sans my-8 max-w-lg">About →</p>
        </div>
        <div className="flex lg:justify-end">
          <p className="text-2xl font-sans max-w-xl">
          Cypher Studio is a digital experience studio researching new interactions between people and collections. With the use of emerging technology, we help engage a new generation of visitors.<br></br><br></br>Our expertise lies within audience participation, virtual collections and interactive platforms for museums, galleries and archives.
          </p>
        </div>
      </div>

      <div className="bg-black rounded-3xl mx-2 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 items-center text-left mx-auto my-36 md:my-48 px-[15%] py-0">
          <div>
            <p className="text-2xl font-sans my-8 max-w-lg text-[#ddd]">Worked with →</p>
          </div>
          <div className='flex lg:justify-end'>
            {/* bounding box */}
          <div className="mb-0 max-w-xl w-full">

            {collaborations.map((collab, index) => (
              <div key={index} className="my-2 mb-0">
                <p className="text-xl md:text-2xl font-sans text-[#ddd] hover:text-white">
                  {collab.name},{" "}
                  <a className="text-[#706f6f] text-sm md:text-lg font-custom_medium hover:text-[#706f6f]">
                    {collab.city}
                  </a>
                </p>
                </div>
            ))}

          </div>
          </div>
        </div>


      </div>

              {/* projects */}
              <div className='mb-36 mt-0 p-2 '>
        {/* <p className="text-2xl font-sans my-8 text-center mx-auto max-w-xl">Our collective consists of designers, developers, curators and XR-specialists with extensive experience within the museum field.</p> */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mx-auto'>

          {/* project 1 */}
          <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/IMG_4715.jpg"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>Amsterdam Museum</p>
              <p className='text-xl text-center font-sans'>Unboxing "Costume Parisien"</p>
            </div>
          </div>

          {/* project 2 */}
          <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/ezgif-196f4719ba2621.gif"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>Museum Boijmans Van Beuningen</p>
              <p className='text-xl text-center font-sans_bold'>
              <a
            href="https://familyofobjects.nl/en"
            target="_blank"
            rel="noopener noreferrer"
            className="font-sans hover:underline text-black hover:text-black"
          >Family of Objects ↗</a>
</p>
            </div>
          </div>

          {/* project 3 */}
          <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/IMG_VanGogh.jpg"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>Van Gogh Museum</p>
              <p className='text-xl text-center font-sans'>Short film</p>
            </div>
          </div>


          {/* <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/Joseph.gif"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>Museum Boijmans Van Beuningen</p>
              <p className='text-xl text-center font-sans'>3D scanning</p>
            </div>
          </div>


          <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/IkBenJo.png"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>Van Gogh Museum</p>
              <p className='text-xl text-center font-sans'>Ik ben Jo</p>
            </div>
          </div>


          <div className="w-full aspect-square">
            <img
              src="/assets/images/banners/knitPlus.gif"
              alt="Footer Banner"
              className="inset-0 w-full h-full object-cover rounded-3xl"
            />
            <div className='my-4'>
              <p className='text-xl text-center font-custom_bold'>AMFI</p>
              <p className='text-xl text-center font-sans'>3D animation</p>
            </div>
          </div> */}


        </div>
      </div>

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 items-center text-left mx-auto my-36 md:my-48 px-[15%]">
  <div className=''>
    <p className="text-2xl font-sans my-8 max-w-lg">Approach →</p>
  </div>
  <div className=" lg:justify-end">

  <p className="text-2xl font-sans my-8 hover:underline  border-black border-[3px] w-fit p-6 rounded-2xl">Projects 🔗</p>
  <p className="text-2xl font-sans my-8 hover:underline  border-black border-[3px] w-fit p-6 rounded-2xl">How we work →</p>
</div>
</div> */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 items-center text-left mx-auto my-36 md:my-48 px-36">
  <div className=''>
    <p className="text-2xl font-sans my-8 max-w-lg">Initiatives →</p>
  </div>
  <div className=" lg:justify-end">

  <p className="text-2xl font-sans">
  Next to commissioned work, we build accessible tools for inclusive and engaging user-experiences – available to all partnering museums. This is in line with our mission to prompt more collaboration in the field of museum technologies.
  </p>

  <p className="text-2xl font-sans my-8 hover:underline border-black border-[3px] w-fit p-6 rounded-2xl">Museum Circle →</p>
</div>
</div> */}

      {/* <div className='bg-black rounded-3xl mx-4'>
<div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 items-center text-left mx-auto my-36 md:my-48 px-36 py-16">
  <div className=''>
    <p className="text-3xl font-sans my-8 max-w-lg text-[#ddd]">Get in touch →</p>
  </div>
  <div className="lg:justify-end">
  <p className="text-3xl font-sans text-[#ddd]">
  All enquiries: <br></br>
hello@cypherstudio.org <br></br>
🇳🇱 +316 13902064 <br></br><br></br>

Address:<br></br>
Sint-agathastraat 54<br></br>
3037 SH Rotterdam<br></br>
The Netherlands<br></br><br></br>
<a className='text-[#706f6f] text-3xl font-sans hover:text-[#706f6f]'>© 2025</a>

  </p>
</div>
</div>
</div> */}




      {/* Bottom text */}

      {/* <div
        className='text-center items-center max-w-prose mb-24 xl:mb-48 mx-auto px-8 '>
        <p className="text-3xl font-sans font-regular text-black">
          Museum Circle is an initiative by{' '}
          <a
            href="https://cypherstudio.org"
            target="_blank"
            rel="noopener noreferrer"
            className="font-custom_bold hover:underline text-black hover:text-black"
          >
            Cypher Studio
          </a>
          , and aligns with our mission to create accessible tools for the future of museums.
        </p>

        <Button
          asChild
          className="my-8 w-full dark bg-transparent border-2 border-black font-sans text-black hover:bg-white hover:text-[black] hover:border-white"
          variant="outline">
          <Link to="/requestaccess">
            Become a member →</Link></Button>
      </div> */}

      {/* text */}
<div
        className='text-center items-center max-w-prose mt-36 mb-8 xl:mb-36 mx-auto px-8 '>
        <p className="text-2xl font-sans font-regular text-black">
        We're a collective of designers, developers and curators. Together, we collaborate on commissioned projects, research, and accessible tools for cultural institutions.
        </p>

        <Button
          asChild
          className="my-8 w-full dark bg-transparent border-2 border-black font-sans text-black hover:bg-black hover:text-[#ddd] hover:border-black"
          variant="outline">
          <Link to="/contact">
            Get in touch →</Link></Button>
      </div>

    </>
  );
}

export default About;