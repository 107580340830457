import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import Membership from './pages/RequestAccess';
import HowWeWork from './pages/HowWeWork';
import Privacy from './pages/PrivacyStatement';
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/404';
import { Analytics } from "@vercel/analytics/react"



function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        {/* Header - Full Width */}
        <Header setMenuOpen={setMenuOpen} />

        {/* Sidebar Menu */}


        {/* Main Content */}
        <main className="flex-grow w-full p-0">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/requestaccess" element={<Membership />} />
            <Route path="/how-we-work" element={<HowWeWork />} />
            <Route path="/privacystatement" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
      <Analytics />
    </Router>
  );
}

export default App;
