import { Separator } from '@/components/ui/separator';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './ui/button';
import { useNavigate } from "react-router-dom";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetFooter,
} from './ui/sheet';


function Header() {
  // State to track if the sheet is open
  const navigate = useNavigate();
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  //

  // For submenu logic
  const [activeMenu, setActiveMenu] = useState("main");
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [titlePath, setTitlePath] = useState(["Menu"]);

  // Example data with sub menus
  const menuGroups = [

    {
      items: [
        { label: "Home", link: "/" },
        { label: "How we work", link: "/how-we-work" },
        { label: "Contact", link: "/contact" },
      ]
    }


  ];

  // Click handler for a main‐menu item
  const handleMainItemClick = (item) => {
    // If there's a subMenu, go deeper
    if (item.subMenu) {
      setActiveMenu("sub");
      setSubMenuItems(item.subMenu);
      setTitlePath((prev) => [...prev, item.label]);
    }
    // Otherwise, if there's a link, navigate directly
    else if (item.link) {
      navigate(item.link);
      // Also close the sheet if you want
      setIsSheetOpen(false);
    }
  };

  // If user wants to go back to main menu (or close sub)
  const handleBackToMain = () => {
    // Return to main
    setActiveMenu("main");
    setSubMenuItems([]);
    // Reset the title path
    setTitlePath(["Menu"]);
  };

  //
  return (
    <header className='sticky top-0'>

{/*menu*/}


{/*logo*/}
      <div className='bg-[#ddd] bg-opacity-95'>
          {/* Center: SVG Logo */}
          <div className="w-full items-center overflow-visible px-4 py-4">
          <Link to="/">
            <img
              src="/assets/logos/CypherStudio_logo.svg"
              alt="Museum Circle Logo"
              className="w-full"
            />
          </Link>
        </div>
    </div>
    
    </header>
  );
}

export default Header;
